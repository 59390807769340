import * as React from 'react';
import { SVGProps } from 'react';

const TwitterLandingPageFooterIconComponent = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.061 7.619c.011.194.011.398.011.603 0 6.182-4.709 13.318-13.317 13.318-2.64 0-5.108-.779-7.155-2.118a9.404 9.404 0 0 0 6.93-1.93 4.672 4.672 0 0 1-4.368-3.254c.292.059.583.089.883.089.429 0 .845-.059 1.235-.166a4.684 4.684 0 0 1-3.757-4.59v-.059c.63.35 1.348.563 2.116.583A4.66 4.66 0 0 1 1.55 6.2c0-.852.233-1.659.632-2.349a13.275 13.275 0 0 0 9.648 4.894 4.27 4.27 0 0 1-.127-1.07A4.68 4.68 0 0 1 16.383 3c1.348 0 2.562.561 3.417 1.475a9.467 9.467 0 0 0 2.97-1.136 4.668 4.668 0 0 1-2.058 2.592 9.424 9.424 0 0 0 2.688-.74A9.556 9.556 0 0 1 21.06 7.62Z"
      fill="currentColor"
    />
  </svg>
);

export default TwitterLandingPageFooterIconComponent;

import { twMerge } from 'tailwind-merge';

const Heading1Style = `font-aeonikFono text-[48px] font-bold leading-[48px] tablet:text-[64px] tablet:leading-[64px]`;
export function Heading1({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <h1 className={twMerge(Heading1Style, className)}>{children}</h1>;
}
Heading1.style = Heading1Style;

const Heading2Style = `font-aeonikFono text-[40px] font-bold leading-[52px] tablet:text-[56px] tablet:leading-[64px]`;
export function Heading2({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <h2 className={twMerge(Heading2Style, className)}>{children}</h2>;
}
Heading2.style = Heading2Style;

const Heading3Style = `font-aeonikFono text-[32px] font-bold leading-[40px] tablet:text-[40px] tablet:leading-[48px]`;
export function Heading3({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <h3 className={twMerge(Heading3Style, className)}>{children}</h3>;
}
Heading3.style = Heading3Style;

const Heading4Style = `font-aeonikFono text-[24px] font-bold leading-[32px] tablet:text-[32px] tablet:leading-[40px]`;
export function Heading4({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <h4 className={twMerge(Heading4Style, className)}>{children}</h4>;
}
Heading4.style = Heading4Style;

const Heading5Style = `font-aeonikFono text-[20px] font-bold leading-[28px] tablet:text-[24px] tablet:leading-[32px]`;
export function Heading5({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <h5 className={twMerge(Heading5Style, className)}>{children}</h5>;
}
Heading5.style = Heading5Style;

const SubheadingStyle = `font-mimopro text-[20px] font-normal leading-[30px] tablet:text-[24px] tablet:leading-[36px]`;
export function Subheading({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <p className={twMerge(SubheadingStyle, className)}>{children}</p>;
}
Subheading.style = SubheadingStyle;

const BodyTextStyle = `font-mimopro text-[18px] font-normal leading-[28px] tablet:text-[20px] tablet:leading-[32px]`;
export function BodyText({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <p className={twMerge(BodyTextStyle, className)}>{children}</p>;
}
BodyText.style = BodyTextStyle;

const BodyTextSmallStyle = `font-mimopro text-[14px] font-normal leading-[20px] tablet:text-[16px] tablet:leading-[24px]`;
export function BodyTextSmall({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <p className={twMerge(BodyTextSmallStyle, className)}>{children}</p>;
}
BodyTextSmall.style = BodyTextSmallStyle;

const ButtonTextStyle = `font-aeonikFono text-[16px] font-bold leading-[24px] tablet:text-[20px]`;
export function ButtonText({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <p className={twMerge(ButtonTextStyle, className)}>{children}</p>;
}
ButtonText.style = ButtonTextStyle;

const ButtonSmallTextStyle = `font-aeonikFono text-[14px] font-bold leading-[20px] tablet:text-[16px] tablet:leading-[24px]`;
export function ButtonSmallText({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <p className={twMerge(ButtonSmallTextStyle, className)}>{children}</p>;
}
ButtonSmallText.style = ButtonSmallTextStyle;

const ButtonSocialTextStyle = `font-mimopro text-[12px] font-bold leading-[24px] tablet:text-[14px]`;
export function ButtonSocialText({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <p className={twMerge(ButtonSocialTextStyle, className)}>{children}</p>
  );
}
ButtonSocialText.style = ButtonSocialTextStyle;

const Label1Style = `font-mimopro text-[12px] font-normal uppercase leading-[16px] tablet:text-[16px] tablet:leading-[24px]`;
export function Label1({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <p className={twMerge(Label1Style, className)}>{children}</p>;
}
Label1.style = Label1Style;

const Label2Style = `font-mimopro text-[12px] font-bold uppercase leading-[16px] tablet:text-[16px] tablet:leading-[24px]`;
export function Label2({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <p className={twMerge(Label2Style, className)}>{children}</p>;
}
Label2.style = Label2Style;

const QuoteStyle = `font-mimopro text-[20px] font-normal italic leading-[28px] tablet:text-[24px] tablet:leading-[32px]`;
export function Quote({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <p className={twMerge(QuoteStyle, className)}>{children}</p>;
}
Quote.style = QuoteStyle;

const CaptionStyle = `font-mimopro text-[16px] font-normal leading-[20px] tablet:text-[20px] tablet:leading-[24px]`;
export function Caption({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <p className={twMerge(CaptionStyle, className)}>{children}</p>;
}
Caption.style = CaptionStyle;

const LinkTextStyle = `font-mimopro text-[14px] font-normal leading-[20px] tablet:text-[18px] tablet:leading-[24px]`;
export function LinkText({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <p className={twMerge(LinkTextStyle, className)}>{children}</p>;
}
LinkText.style = LinkTextStyle;

const FormCheckboxTextStyle = `font-mimopro text-[12px] font-normal leading-[24px] tablet:text-[14px]`;
export function FormCheckboxText({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <p className={twMerge(FormCheckboxTextStyle, className)}>{children}</p>
  );
}
FormCheckboxText.style = FormCheckboxTextStyle;

const FormErrorTextStyle = `font-mimopro text-[12px] font-normal leading-[24px]`;
export function FormErrorText({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <p className={twMerge(FormErrorTextStyle, className)}>{children}</p>;
}
FormErrorText.style = FormErrorTextStyle;

import * as React from 'react';
import { SVGProps } from 'react';

const MediumLandingPageFooterIconComponent = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.216 12c0 2.963-.495 5.375-1.112 5.375-.616 0-1.112-2.412-1.112-5.375s.496-5.375 1.112-5.375c.617 0 1.112 2.412 1.112 5.375Zm-2.84 0c0 3.309-1.412 6-3.16 6-1.747 0-3.168-2.691-3.168-6s1.42-6 3.168-6c1.749 0 3.16 2.691 3.16 6Zm-6.937 0a6.356 6.356 0 1 1-1.845-4.497 6.333 6.333 0 0 1 1.845 4.478V12Z"
      fill="currentColor"
    />
  </svg>
);

export default MediumLandingPageFooterIconComponent;

import { useEffect, useState } from 'react';

type ConfettiType = {
  color: string;
  shadowColor?: string;
  shadowDirectionLeft?: boolean;
  className?: string;
};

export function NavBarConfetti() {
  const confettiVersionOne = [
    {
      color: 'bg-marketing-accent-pink',
      shadowColor: 'bg-marketing-accent-white',
      shadowDirectionLeft: false,
      className: 'left-[85%] top-[50%] ',
    },
    {
      color: 'bg-marketing-accent-blue',
      className: 'left-[40%] top-[10%]',
    },
  ];
  const confettiVersionTwo = [
    {
      color: 'bg-marketing-accent-orange',
      shadowColor: 'bg-marketing-accent-white',
      shadowDirectionLeft: true,
      className: 'left-[85%] top-[20%] ',
    },
    {
      color: 'bg-marketing-accent-darkBlue',
      className: 'left-[60%] top-[0%]',
    },
  ];

  return pickRandomConfettiBackground(confettiVersionOne, confettiVersionTwo);
}

export function LearnPathsConfetti() {
  const confettiVersionOne = [
    {
      color: 'bg-marketing-accent-darkBlue',
      className: 'left-[20%] top-0',
    },
    {
      color: 'bg-marketing-accent-pink',
      className: 'left-[65%] top-[-1%]',
    },
  ];

  const confettiVersionTwo = [
    {
      color: 'bg-marketing-accent-darkBlue',
      className: 'left-[-5%] top-0',
    },
    {
      color: 'bg-marketing-accent-blue',
      className: 'left-[60%] top-[-1%]',
    },
  ];

  return pickRandomConfettiBackground(confettiVersionOne, confettiVersionTwo);
}

export function DesktopVsMobileConfetti() {
  const confettiVersionOne = [
    {
      color: 'bg-marketing-accent-orange',
      className: 'left-[95%] top-[20%] ',
    },
    {
      color: 'bg-marketing-accent-yellow',
      className: 'left-[-5%] top-[20%]',
    },
    {
      color: 'bg-marketing-accent-blue',
      className: 'left-[85%] top-[-5%]',
    },
    {
      color: 'bg-marketing-accent-darkBlue',
      className: 'left-[10%] top-[10%]',
    },
  ];

  const confettiVersionTwo = [
    {
      color: 'bg-marketing-accent-yellow',
      className: 'left-[95%] top-[20%] ',
    },
    {
      color: 'bg-marketing-accent-orange',
      className: 'left-[90%] top-[-8%]',
      shadowColor: 'bg-marketing-accent-darkBlue',
      shadowDirectionLeft: false,
    },
    {
      color: 'bg-marketing-accent-blue',
      className: 'left-[0%] top-[20%]',
    },
  ];

  return pickRandomConfettiBackground(confettiVersionOne, confettiVersionTwo);
}

export function BenefitsConfetti() {
  const confettiVersionOne = [
    {
      color: 'bg-marketing-accent-darkBlue',
      className: 'left-[0%] top-[59%]',
    },
    {
      color: 'bg-marketing-accent-yellow',
      className: 'left-[95%] top-[10%]',
    },
  ];

  const confettiVersionTwo = [
    {
      color: 'bg-marketing-accent-darkBlue',
      className: 'left-[-5%] top-[40%] ',
    },
    {
      color: 'bg-marketing-accent-pink',
      className: 'left-[92%] top-[0%]',
    },
    {
      color: 'bg-marketing-accent-yellow',
      className: 'left-[95%] top-[70%]',
    },
  ];
  return pickRandomConfettiBackground(confettiVersionOne, confettiVersionTwo);
}

export function SuccessStoriesConfetti() {
  const confettiVersionOne = [
    {
      color: 'bg-marketing-accent-orange',
      shadowColor: 'bg-marketing-accent-darkBlue',
      shadowDirectionLeft: false,
      className: 'left-[-5%] top-[40%]',
    },
    {
      color: 'bg-marketing-accent-blue',
      shadowColor: 'bg-marketing-accent-darkBlue',
      shadowDirectionLeft: true,
      className: 'left-[40%] top-[-12%]',
    },
  ];

  const confettiVersionTwo = [
    {
      color: 'bg-marketing-accent-blue',
      className: 'left-[-5%] top-[40%]',
    },
    {
      color: 'bg-marketing-accent-purple',
      shadowColor: 'bg-marketing-accent-darkBlue',
      shadowDirectionLeft: true,
      className: 'left-[40%] top-[-10%]',
    },
  ];
  return pickRandomConfettiBackground(confettiVersionOne, confettiVersionTwo);
}

export function FooterConfetti() {
  const confettiVersionOne = [
    {
      color: 'bg-marketing-accent-orange',
      shadowColor: 'bg-marketing-accent-darkBlue',
      shadowDirectionLeft: false,
      className: 'left-[60%] top-[75%]',
    },
    {
      color: 'bg-marketing-accent-yellow',
      shadowColor: 'bg-marketing-accent-white',
      shadowDirectionLeft: true,
      className: 'left-[80%] top-[10%]',
    },
    {
      color: 'bg-marketing-accent-blue',
      className: 'left-[40%] top-[15%]',
    },
  ];

  const confettiVersionTwo = [
    {
      color: 'bg-marketing-accent-yellow',
      shadowColor: 'bg-marketing-accent-darkBlue',
      shadowDirectionLeft: true,
      className: 'left-[60%] top-[75%]',
    },
    {
      color: 'bg-marketing-accent-orange',
      shadowColor: 'bg-marketing-accent-white',
      shadowDirectionLeft: false,
      className: 'left-[80%] top-[10%]',
    },
    {
      color: 'bg-marketing-accent-pink',
      className: 'left-[40%] top-[15%]',
    },
  ];
  return pickRandomConfettiBackground(confettiVersionOne, confettiVersionTwo);
}

function pickRandomConfettiBackground(
  confettiVersionOne: ConfettiType[],
  confettiVersionTwo: ConfettiType[],
) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [confettis, setConfettis] = useState<ConfettiType[]>([]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const confettis = [confettiVersionOne, confettiVersionTwo];
    const randomIndex = Math.floor(Math.random() * confettis.length);
    const randonConfetti = confettis[randomIndex];
    setConfettis(randonConfetti);
  }, []);

  return createConfettiBackground(confettis);
}

export function FeaturedByCompanyConfetti() {
  const confettiVersionOne = [
    {
      color: 'bg-marketing-accent-darkBlue',
      className: 'bottom-0 left-[60%]',
    },
  ];

  return createConfettiBackground(confettiVersionOne, 'hidden tablet:block');
}

export function LoginPageConfetti() {
  const confettiVersionOne = [
    {
      color: 'bg-marketing-accent-pink',
      className: 'top-[60px] tablet:left-1/2 desktop:left-[30%]',
    },
    {
      color: 'bg-marketing-accent-blue',
      className:
        'tablet:top-[90%] tablet:left-[60%] desktop:left-[70%] desktop:top-1/2 desktop:right-auto',
    },
    {
      color: 'bg-marketing-accent-darkBlue',
      className: 'left-[100px] tablet:bottom-[100px] desktop:bottom-[150px]',
    },
    {
      color: 'bg-marketing-accent-purple',
      className: 'left-[160px] tablet:bottom-[160px] desktop:bottom-[210px]',
    },
  ];

  return createConfettiBackground(confettiVersionOne, 'hidden tablet:block');
}

export function RegisterPageConfetti() {
  const confettiVersionOne = [
    {
      color: 'bg-marketing-accent-blue',
      className:
        'tablet:bottom-[100px] desktop:bottom-[150px] tablet:left-1/2 desktop:left-[30%]',
    },
    {
      color: 'bg-marketing-accent-green',
      className:
        'tablet:top-[90%] tablet:left-[60%] desktop:left-[70%] desktop:top-1/2 desktop:right-auto',
    },
    {
      color: 'bg-marketing-accent-yellow',
      className: 'left-[160px] top-[60px] ',
    },
    {
      color: 'bg-marketing-accent-purple',
      className: 'left-[100px] top-[120px]',
    },
  ];

  return createConfettiBackground(confettiVersionOne, 'hidden tablet:block');
}

function createConfettiBackground(
  confettis: ConfettiType[],
  className?: string,
) {
  return (
    <div
      className={`absolute bottom-0 left-0 top-0 z-[-1] h-full w-full ${className}`}
    >
      {confettis.map((c, index) => (
        <div
          key={index}
          className={`h-[30px] w-[30px] tablet:h-[60px] tablet:w-[60px] ${c.color} absolute ${c.className}`}
        >
          <div
            className={`${c.shadowColor ? 'block' : 'hidden'} ${
              c.shadowColor
            }  absolute ${
              c.shadowDirectionLeft
                ? 'left-[100%] top-[100%]'
                : 'left-[-100%] top-[100%]'
            }  h-[30px] w-[30px] tablet:h-[60px] tablet:w-[60px]`}
          ></div>
        </div>
      ))}
    </div>
  );
}
